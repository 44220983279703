<template>
  <div class="faqSection">
    <div class="container">
      <animated-component>
        <div class="heading">
          <img 
          src="~@/images/loop_waai.webp"
	  />
          <div>FAQ</div>
        </div>
      </animated-component>
      <div class="dropdownWrapper" v-for="item in faq" :key="item.title">
        <animated-component animationType="right">
          <DropdownItem class="faq" :title="item.title" :description="item.description" />
        </animated-component>
      </div>
    </div>
  </div>
</template>
<script setup>
import DropdownItem from './DropdownItem.vue';

const faq = [
  {
    title: 'What is mint price?',
    description: ['Ξ0.00756551 per NFT + gas. Also mintable at 41.123 tokens (418 Tokens that will be airdropped to your wallet) + gas.']
  },
  {
    title: 'Whitelist mint Details?',
    description: [
      "Normally hyped up projects have a whitelist for select members. You are all select, so no need for a whitelist."
    ]
  },
  {
    title: 'Wen Public mint start?',
    description: [
      'Now. But you knew this already.'
    ]
  },
  {
    title:
      'Is there a maximum mint limit per transaction?',
    description: [
      'Yes, you can mint a maximum of 10 WAAIs per transaction! But you would need to mint it directly at the smart contract. On our website, you can only mint 1 at a time.'
    ]
  },
  {
    title: 'How will minting work?',
    description: [
      'Most NFT projects mint an “unrevealed” NFT - that is, you do not see what you got until reveal day. On that day, the real metadata is set live and users are able to “refresh metadata” on any NFT exchange marketplace. We will be revealing immediately, so you will be able to see your newly minted WAAI!'
    ]
  },
  {
    title: 'Can I mint using a hardware wallet?',
    description: ['Yes you can.']
  },

  {
    title: 'What are the Licensing rights / Intellectual property?',
    description: [
      'All WAAI holders are given commercial rights to their specific WAAI NFT. You can sell its likeness, make derivatives of it or you can give others permission to use it. It’s yours. We’ve given licensing rights over for the NFT’s, but not our logo, names, site images, or other marketing material. If it isn’t your NFT, it isn’t your Intellectual Property. Most of these logos, names, and images related to WAAI have either trademarked pending status, or are the intellectual property of the artist(s).',
      'Regarding the term “WAAI" and "WAAIs". We allow usage of the term only when used in conjunction with your NFT.',
      'Think of it like this: You own your NFT, not WAAI, so accurately representing yourself / brand / product, while not infringing on our brand, is important.'
    ]
  }
];

</script>
<style scoped lang="scss">
.faqSection {
  background-color: black;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 0px;
  margin: auto;

  .imageContainer {
    max-width: 600px;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .container {
    width: 100%;
    padding: 40px;
    max-width: 1600px;

    .heading {
      font-size: 32px;
      font-weight: 700;
      text-transform: uppercase;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      img {
        height: 35px;
        width: 35px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 800px) {
  .faqSection {
    grid-template-columns: 1fr;

    .container {
      .heading {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 600px) {
  .faqSection .container .dropdownWrapper .faq {
    margin: 20px 0;
  }
}
</style>

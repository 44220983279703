<template lang="">
  <div class="none">
    <div class="heading">Page does not exist!</div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">

  .none {
    padding: 60px 20px;
    padding-bottom: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    .heading {
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 40px;
      line-height: 1em;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }
  }
</style>

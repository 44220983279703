// Accessible error status messages for future localization.
const logName = 'errorHandling.js';

const GenericError = `An error occurred. Please try again.`;
const MESSAGES = {
  'CannotTransferIncorrectAmount()': GenericError,
  'PaymentTransferFailed()': `An error occurred during payment. Please try again.`,
  'CannotVerifyAsWhitelistMember()': `You are not whitelisted.`,
  'CannotExceedWhitelistAllowance()': `You have reached your mint limit during whitelist.`,
  'CannotBuyZeroItems()': GenericError,
  'CannotBuyFromEndedSale()': GenericError,
  'CannotExceedPerTransactionCap()': GenericError,
  'CannotExceedPerCallerCap()': `You have reached your mint limit.`,
  'CannotExceedTotalCap()': `All impostors have been minted.`,
  'CannotUnderpayForMint()': GenericError,
  'RefundTransferFailed()': GenericError,
  'ERC20: transfer amount exceeds balance': `You don't have enough SUPER tokens to mint.`,
  WrongNetwork:
    'We are unable to connect to the right network. Can you try changing networks and reloading?'
};

// Used to check for exceptions that may occur during transition periods
// (wallet is locked, walletconnect is setting up, etc)
export const checkException = async er => {
  fncRegex = /call revert exception (.*())\"/g;
  while ((match = fncRegex.exec(er))) {
    return true;
  }
  return false;
};

// Converts 'er' message into a user friendly string
export const errMsg = er => {
  let uiErr = er.toString();
  let match = null;

  // see if we have a custom error (and if we do, parse it out)
  let fncRegex = /custom error \'(.*())'\"/g;
  // see custom errors in drop contract
  while ((match = fncRegex.exec(uiErr))) {
    const [, key] = match;
    uiErr = MESSAGES[key];
  }

  // see if we reverted for any reason (and if we do, parse it out)
  fncRegex = /reverted with reason string \'(.*())'\"/g;
  // most likely culprit: 'ERC20: transfer amount exceeds balance'
  while ((match = fncRegex.exec(uiErr))) {
    const [, key] = match;
    uiErr = MESSAGES[key];
  }

  fncRegex = /call revert exception (.*())\"/g;
  // most likely culprit: 'user does not have enough ETH or SUPER
  while ((match = fncRegex.exec(uiErr))) {
    const [, key] = match;
    uiErr = MESSAGES[key];
  }

  if (MESSAGES[er] != null) {
    uiErr = MESSAGES[er];
  }

  // if the network is too slow, we may not properly parse the revert reason...
  if (uiErr == null || uiErr.length > 100) {
    uiErr = GenericError;
  }

  // Return a user-friendly error for the failure.
  return uiErr;
};

const BLACKLIST = [
  /is not a function/i,
  /is undefined/i,
  /is not defined/i,
  /cannot read properties/i,
  /\[object object\]/i,
  /typeerror/i
];

// dispatches error message
export const processError = async (er, shouldClear, dispatch) => {
  // Return a user-friendly error for the purchase failure.
  let shouldSkip = typeof er !== 'string' || BLACKLIST.some(reg => reg.test(er));
  if (shouldSkip) {
    return;
  }
  if (shouldClear) {
    await dispatch('alert/clear', '', { root: true });
  }
  await dispatch(
    'alert/info',
    {
      message: er,
      duration: 10000
    },
    { root: true }
  );
};

<template>
  <div id="container">
    <!-- Each image is 350px by 233px -->
    <div class="photobanner">
      <div class="imgContainer">
	<img src="~@/images/waais/1.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/14.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/3.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/4.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/5.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/6.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/7.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/8.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/9.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/10.jpg.webp" alt />
      </div>
    </div>

    <div class="photobanner offset">
      <div class="imgContainer offset">
	<img src="~@/images/waais/11.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/11.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/12.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/13.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/2.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/15.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/16.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/17.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/18.jpg.webp" alt />
      </div>
      <div class="imgContainer">
	<img src="~@/images/waais/19.jpg.webp" alt />
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

#container {
  height: 65vh;
  overflow: hidden;
  display: flex;
  gap: 15px;
}

.photobanner {
  width: 250px;
  height: 2500px;
  animation: bannermove 30s linear infinite;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .imgContainer {
    height: 250px;
    width: 250px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.offset {
  margin-top: 115px;
}

@keyframes bannermove {
  0% {
    margin-top: 0px;
  }
  100% {
    margin-top: -1250px;
  }
}

@media (max-width: 800px) {
  #container {
    height: 500px;
    overflow: hidden;
    display: flex;
    gap: 10px;
  }
  .photobanner {
    width: 100%;
    height: 100%;
    gap: 5px;
    .imgContainer {
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>

<template>
  <Ethereum :callback="initialize" />
  <div v-if="!isConnected" style="margin-top: 100px">
    <ConnectWallet />
  </div>
  <div v-else-if="isLoading || !isReady" class="skeleton">
    <MintSkeleton />
  </div>
  <div v-else class="mint">
    <div class="heading">Mint Your Own #WAAI</div>
    <div class="imageContainer">
      <img
        src="~@/images/infinity_loop_static.webp"
        srcset="~@/images/infinity_loop.webp"
      />
    </div>
    <div v-if="!soldOut">
      <div v-if="canMintNow > 0">
        <p class="text">You can still mint another {{ canMintNow }} WAAI(s).</p>
        <div v-if="!isOpen">
          <div
            style="border: 1px solid white; border-radius: 10px; padding: 15px"
          >
            <p style="text-align: center" class="text">Minting Beginning In:</p>
            <Countdown
              :startDate="store.state.mint.startDate"
              :endDate="store.state.mint.startDate + 1000000"
            ></Countdown>
          </div>
        </div>
        <div class="dropdownWrapper" v-else>
          <Button @click="mint({ qnt: 1, type: ETH_PAYMENT_TYPE })"
            >Mint for {{ currentPrice }} ETH</Button
          >
          <div v-if="tokenIsApproved">
            <Button @click="mint({ qnt: 1, type: TOKEN_PAYMENT_TYPE })"
              >Mint for {{ currentTokenPrice }} Tokens</Button
            >
          </div>
          <div v-else>
            <Button
              class="disabled"
              @click="mint({ qnt: 1, type: TOKEN_PAYMENT_TYPE })"
              >Approve and mint for {{ currentTokenPrice }} Tokens</Button
            >
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text">You have minted all the WAAIs for this wallet</p>
      </div>
    </div>
    <div v-else>
      <p class="text">Sold Out!</p>
    </div>
  </div>
</template>
<script setup>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { ethers } from 'ethers';
import initializeConfig from '/src/initialize-config';
import useInterval from '/src/utility/useInterval';

import Ethereum from '/src/components/common/Ethereum.vue';
import MintSkeleton from './components/MintSkeleton.vue';

import Button from '/src/components/ui/Button.vue';
import Countdown from '/src/components/ui/Countdown.vue';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';

const ETH_PAYMENT_TYPE = ref('ETH');
const TOKEN_PAYMENT_TYPE = ref('TOKEN');

const isLoading = ref(true);
const transactionSubmitting = ref(false);
const store = useStore();
let config; //global config

const minted = computed(() => store.state.mint.minted);

let now = ref(new Date());

onMounted(() => {
  window.scroll(0, 0);

  // retrieve shop config (when wallet not connected, would rely on infura provider)
  loadShopConfig().then();
});

const initialize = async () => {
  isLoading.value = true; // this controls the initial skeletor
  if (isConnected.value) {
    await loadAll();
  }
  isLoading.value = false;
};

const loadAll = async () => {
  await loadShopConfig();
  await getSoldCount();
  await loadMintedItems();
  await loadTokenInfo();
};

const loadShopConfig = async () => {
  // load global config
  config = await initializeConfig();

  // delay to ensure all ABIs have loaded
  // not needed once we load ABIs directly instead of via http/get
  // await new Promise(resolve => setTimeout(resolve, 100));

  //console.info("loading shop config at address=", config.shopAddress[store.state.ethers.networkId]);
  await store.dispatch(
    'mint/loadShopConfig',
    {},
    {
      root: true
    }
  );
  getSoldCount();
};

const loadMintedItems = async () => {
  if (isConnected.value && config != null) {
    //console.info("loading minted items at address=", config.itemCollections[store.state.ethers.networkId]);
    await store.dispatch(
      'mint/loadMintedItems',
      {
        ethersStore: store.state.ethers // wallet address (specifically: walletConnect)
      },
      {
        root: true
      }
    );
  }
};

const getSoldCount = async () => {
  if (isReady.value) {
    //console.info("loading minted items at address=", config.itemCollections[store.state.ethers.networkId]);
    await store.dispatch(
      'mint/getSoldCount',
      {},
      {
        root: true
      }
    );
  }
};

// loads allowance and amount of owned tokens
const loadTokenInfo = async () => {
  if (isConnected.value) {
    await store.dispatch(
      'mint/loadTokenInfo',
      { ethersStore: store.state.ethers },
      {
        root: true
      }
    );
  }
};

const approveToken = async () => {
  transactionSubmitting.value = true;
  await store.dispatch('mint/approveToken', {}, { root: true });
  transactionSubmitting.value = false;
};

const isConnected = computed(() => {
  return store.state.ethers.canSign;
});

// update 'now' to current time every 1 sec
useInterval(() => {
  now.value = new Date();
}, 1000);

// update current item price every 5 sec
useInterval(async () => {
  await getSoldCount();
  await loadMintedItems();
}, 1000 * 15);

const isReady = computed(() => {
  return store.state.mint.shopConfig != null;
});

const isOpen = computed(() => {
  // uses shopConfig start/end times
  return (
    isReady.value &&
    now.value >= ethStartTime.value &&
    now.value <= ethEndTime.value
  );
});

const isOver = computed(() => {
  // uses shopConfig start/end times
  return !isReady.value || now.value >= ethEndTime.value;
});

const isComingSoon = computed(() => {
  // uses shopConfig start/end times
  return (
    isReady.value &&
    now.value < tokenStartTime.value &&
    now.value < ethEndTime.value &&
    now.value < ethEndTime.value
  );
});

// computed/helper functions for shop config data
const ethStartDate = computed(() => {
  return new Date(ethStartTime.value);
});

const ethStartTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.ethStartTime;
});

const ethEndDate = computed(() => {
  return new Date(ethEndTime.value);
});

const ethEndTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.ethEndTime;
});

const ethPrice = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.ethStartingPrice;
});

const tokenStartTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.tokenStartTime;
});

const tokenEndTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.tokenEndTime;
});

const tokenStartingPrice = computed(() => {
  if (!isReady.value) return 0;
  return store.state.mint.shopConfig.tokenStartingPrice;
});

const sold = computed(() => {
  if (!isReady.value) return 0;
  return store.state.mint.soldCount;
});

const totalCap = computed(() => {
  if (!isReady.value) return 0;
  return store.state.mint.shopConfig.totalCap.toNumber();
});

const callerCap = computed(() => {
  if (!isReady.value) return 1;
  return store.state.mint.shopConfig.callerCap.toNumber();
});

const transactionCap = computed(() => {
  if (!isReady.value) return 1;
  return store.state.mint.shopConfig.transactionCap.toNumber();
});

let totalMinted = computed(() => minted.value || 0);

let canMintNow = computed(() =>
  Math.max(callerCap.value - totalMinted.value, 0)
);

const mintQtySelected = ref(transactionCap.value);

let mintQty = computed({
  get() {
    return mintQtySelected.value > canMintNow.value
      ? Math.max(canMintNow.value, 1)
      : mintQtySelected.value;
  },
  set(v) {
    mintQtySelected.value = v;
  }
});

let hasEnoughToken = computed(() => {
  const hasBalances = store.state?.mint?.balances;
  if (hasBalances) {
    let { tokenBalance } = store.state.mint.balances;
    let cost = parseInt(tokenStartingPrice.value);
    return tokenBalance.gte(ethers.utils.parseEther(cost.toString()));
  }
  return false;
});

let tokenIsApproved = computed(() => {
  const hasBalances = store.state?.mint?.balances;
  if (hasBalances) {
    let { tokenAllowance } = store.state.mint.balances;
    let cost = parseInt(tokenStartingPrice.value);
    return tokenAllowance.gte(ethers.utils.parseEther(cost.toString()));
  }
  return false;
});

const currentPrice = computed(() => {
  const contractCurrentPrice = store.state.mint.currentPrice;
  if (contractCurrentPrice == null && isReady.value) {
    return ethPrice.value;
  }
  return contractCurrentPrice;
});

const currentTokenPrice = computed(() => {
  return store.state.mint.shopConfig.tokenStartingPrice;
});

const currentAddress = computed(() => {
  return store.state.ethers.address;
});

const allowance = computed(() => {
  if (!isReady.value) return 0;
  return ethers.utils.formatEther(store.state.mint.balances.tokenAllowance);
});

const mint = async obj => {
  let qnt = obj.qnt;
  let useToken = obj.type != ETH_PAYMENT_TYPE.value ? true : false;

  transactionSubmitting.value = true;
  if (useToken && !hasEnoughToken.value) {
    await store.dispatch(
      'alert/error',
      {
        message: 'You do not have enough tokens for a mint',
        duration: 10000
      },
      { root: true }
    );
    transactionSubmitting.value = false;
    return;
  } else {
    if (!useToken) {
      let { ethBalance } = store.state.mint.balances;
      let ethCost = store.state.mint.shopConfig.ethStartingPriceWei;
      if (!ethBalance.gte(ethCost.mul(qnt))) {
        await store.dispatch(
          'alert/error',
          {
            message: 'You do not have enough ETH for a mint',
            duration: 10000
          },
          { root: true }
        );
        transactionSubmitting.value = false;
        return;
      }
    }
  }

  // otherwise, mint....
  await store.dispatch(
    'mint/mint',
    {
      qnt: qnt, // amount of impostors to mint
      isToken: useToken, // user by store/mint.service to correctly calculate amounts to be paid
      mintStore: store.state.mint, // mint prices and whitelists
      ethersStore: store.state.ethers // wallet address (specifically: walletConnect)
    },
    { root: true }
  );

  // when successful, delay a tiny bit before refreshing store config (and sold values)
  await new Promise(resolve => setTimeout(resolve, 500));

  await Promise.all([loadShopConfig(), loadMintedItems(), loadTokenInfo()]);

  transactionSubmitting.value = false;
};

watch(currentAddress, (curAdd, oldAdd) => {
  if (curAdd == null) {
    // wallet has locked or disconnected altogether
    store.state.ethers.canSign = false;
    store.dispatch('login/initialize');
  }

  // essentially waits until wallet is ready
  ensureWalletConnected(400000)
    .then(loadAll)
    .catch(err => {
      let do_nothing = err;
    });
});

// this following function should NOT be required and
// we should be able to watch over isConnected
// TODO: either get the watch to work or move this into ethers.service
function ensureWalletConnected(timeout) {
  var start = Date.now();
  return new Promise(waitForWallet);

  // waitForWallet makes the decision whether the condition is met
  // or not met or the timeout has been exceeded which means
  // this promise will be rejected
  function waitForWallet(resolve, reject) {
    // check if wallet connect has ever been enabled in the past
    store.commit('checkWalletConnectStore');
    // if we have connected previously, dispatch automagic connection
    if (store.state?.walletconnect?.connected) {
      store.dispatch('ethers/connectWCProvider');
    }
    if (store.state?.ethers?.canSign) resolve(store.state.ethers.canSign);
    else if (timeout && Date.now() - start >= timeout)
      reject(new Error('timeout'));
    else setTimeout(waitForWallet.bind(this, resolve, reject), 30);
  }
}

let soldOut = computed(() => sold.value >= totalCap.value);
</script>
<style scoped lang="scss">
.skeleton {
  padding: 60px 20px;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mint {
  padding: 60px 20px;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  .mintWrapper {
    margin-bottom: 20px;

    .filled {
      border: 1px solid #777;
      border-radius: 5px;
      padding: 15px;
    }

    .allocation {
      text-align: center;
      padding: 40px;
      font-size: 16px;
      line-height: 1em;
      font-weight: 700;
      border: 2px solid white;
      border-radius: 5px;
    }
  }

  .description {
    padding-top: 20px;
    opacity: 0.8;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    max-width: 420px;
    line-height: 1.4em;
  }

  .tabs {
    display: flex;
    justify-content: center;
  }

  .dropdownWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .disabled {
    background-color: red;
  }
  .heading {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 1em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .text {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
  }

  .imageContainer {
    max-width: 250px;
    max-height: 250px;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      border: 2px solid white;
    }
  }
}

@media (max-width: 700px) {
  .mint .heading {
    font-size: 12vw;
  }
}
</style>

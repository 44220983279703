<template>
  <div class="splashArea">
    <div class="background">
      <div class="image"></div>
      <div class="image"></div>
      <div class="image"></div>
    </div>
    <div class="gradient"></div>
    <div class="aboutSection">
      <div class="container">
        <transition appear name="fade">
          <h2>#WAAI</h2>
        </transition>

        <transition appear name="fade1">
          <div class="image">
            <img src="~@/images/waai_logo.webp"/>
          </div>
        </transition>

        <transition appear name="fade1">
          <p class="description">
	  We are here to celebrate the diverse and unique perspectives of investors around the world. Our collection of NFTs represents a wide range of backgrounds, beliefs, and experiences. From first-time investors to seasoned veterans, our goal is to showcase the richness and diversity of the global investing community.
          </p>
        </transition>

        <transition appear name="fade2">
          <p class="description">
	  We're excited to invite you to participate in our NFT mint. Each of the unique 418 NFTs in our collection is a one-of-a-kind representation of a possible investing journey. With each purchase, you'll not only own a piece of art but also possibly a piece of someone's story. Join us in celebrating the power of diversity in investing. Browse our collection, choose your favorite NFT, and become a part of the movement.
          </p>
        </transition>

        <transition appear name="fade2">
          <p class="description">
	  This mint is part of Babson's Blockchain Ventures class.
          </p>
        </transition>

        <!-- Button -->
        <transition appear name="fade3">
          <Button
            @click="openUrl('https://opensea.io/collection/weareallinvestors')"
          >Find Us On OpenSea</Button>
        </transition>
      </div>
      <div class="downCaret">
        <a href="#about" class="link down" v-smooth-scroll>
          <DownCaretIcon />
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
// Imports.
import { ref } from 'vue';

// Component imports.
import Button from '/src/components/ui/Button.vue';
import DownCaretIcon from '@/components/icons/DownCaretIcon.vue';

let now = ref(new Date());
setInterval(() => {
  now.value = new Date();
}, 1000);

const openUrl = url => {
  window.open(url, '_blank')
};

</script>
<style scoped lang="scss">
.splashArea {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .background {
    transform-style: preserve-3d;
    overflow: hidden;
    will-change: transform;
    position: absolute;
    display: flex;
    height: 100vh;
    animation: slideshow 40s linear infinite;

    .image {
      width: 100vw;
      height: 100%;
	background-image:  url("~@/images/abstract_bg.webp"), url("~@/images/abstract_bg_static.webp");
      background-position: 50% 50%;
      background-size: 100%;
      background-attachment: scroll;
      overflow: hidden;
    }
  }

  .gradient {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .navBar {
    padding: 20px;
    margin-right: 40px;

    .container {
      display: flex;
      justify-content: center;

      .link {
        display: flex;
        padding: 15px 20px;
        align-items: center;
        border-radius: 40px;
        transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: #fff;
        text-decoration: none;
        font-size: 16px;

        &:hover {
          cursor: pointer;
          background-color: hsla(0, 0%, 100%, 0.1);
        }

        &:active {
          background-color: hsla(0, 0%, 100%, 0.2);
        }
      }

      .connect {
        position: absolute;
        right: 20px;
        box-shadow: inset 0 0 0 1px #fff;
      }
    }
  }

  .aboutSection {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .container {
      display: flex;
      width: 1800px;
      max-width: 90%;
      margin-right: auto;
      margin-left: auto;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #fff;
      gap: 20px;

      .description {
        max-width: 750px;
        font-weight: bold;
      }

      .mintingCountdownWrapper {
        padding: 20px;
        border: 2px solid white;
        border-radius: 10px;
        .mintingTitle {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 20px;
        }
      }

      p {
        font-size: 16px;
      }

      .image {
        max-width: 450px;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .logo {
        width: 900px;
        margin-bottom: 40px;
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
        opacity: 1;
      }

      h2 {
        font-size: 30px;
      }

      .mintingWrapper {
        min-width: 200px;
        padding: 20px 30px;
        background-image: linear-gradient(180deg, #ffd915, #ffa41c);
        color: #000;
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        display: flex;

        .space {
          margin-left: 10px;
        }
      }
    }

    .downCaret {
      position: absolute;
      color: #fff;
      animation: downCaret 1100ms infinite;
      cursor: pointer;
      transition: all 100ms ease;
      width: 100%;
      display: flex;
      justify-content: center;

      .link {
        color: white;
        width: 100%;
        text-align: center;
      }

      &:hover {
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.95);
      }
    }

    @keyframes downCaret {
      0% {
        bottom: 9vh;
      }
      50% {
        bottom: 7vh;
      }
      100% {
        bottom: 9vh;
      }
    }
  }
}

@media (max-width: 1050px) {
  .splashArea .aboutSection .container h2 {
    font-size: 4vw;
  }

  .splashArea .aboutSection .container .mintingWrapper {
    font-size: 4vw;
  }

  .splashArea .aboutSection .container .logo {
    display: none;
  }
}
</style>

<style scoped>
/* Fade animation */
.fade-enter-active,
.fade1-enter-active,
.fade2-enter-active,
.fade3-enter-active {
  transition: all 1000ms ease;
}

.fade-enter-from,
.fade1-enter-from,
.fade2-enter-from,
.fade3-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.fade1-enter-active {
  transition-delay: 400ms;
}

.fade2-enter-active {
  transition-delay: 800ms;
}

.fade3-enter-active {
  transition-delay: 800ms;
}
</style>

<template>
  <Ethereum :callback="initialize" />
  <div class="myClones">
    <div class="cloneGrid" v-if="isLoading">
      <CloneSkeleton v-for="(item, index) in 3" :key="index" />
    </div>
    <div class="cloneGrid" v-else-if="!isLoading && owned.length > 0">
      <Clone
        v-for="(clone, index) in owned"
        :key="index"
        :clone="clone"
        @click="navigateToOpensea(clone)"
      />
    </div>
    <div class="none" v-else>
      <div class="heading">No NFTs in your wallet (yet!)</div>
    </div>
  </div>
</template>
<script setup>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ethers } from 'ethers';

import Clone from './components/Clone.vue';
import CloneSkeleton from './components/CloneSkeleton.vue';
import Ethereum from '/src/components/common/Ethereum.vue';

import initializeConfig from '/src/initialize-config';

const isEthLoaded = ref(false);
const isLoadingMyItems = ref(true);
const configIsLoading = ref(true);

let config; //global config

const navigateToOpensea = clone => {
  const url = `https://opensea.io/assets/${
    config.itemCollections[config.forceNetwork]}/${ethers.BigNumber.from(`0x${clone.tokenId}`).toString()}`;

  window.open(url, '_blank');
};

onMounted(() => {
  window.scroll(0, 0);

  // retrieve shop config (when wallet not connected, would rely on infura provider)
  loadShopConfig().then();
});

const initialize = async () => {
  isEthLoaded.value = true;
  isLoading.value = true; // this controls the initial skeletor
  if (isConnected.value) {
    await loadAll();
  }
  isLoading.value = false;
};

const isLoading = computed(
  () => !isEthLoaded.value || isLoadingMyItems.value || configIsLoading.value
);

const loadAll = async () => {
  await loadShopConfig();
  await loadOwnedItems();
};

const owned = computed(() => {
  return store.state.mint.owned;
});

const loadShopConfig = async () => {
  // load global config
  config = await initializeConfig();

  await store.dispatch(
    'mint/loadShopConfig',
    {},
    {
      root: true
    }
  );

  configIsLoading.value = false;
};

const currentAddress = computed(() => {
  return store.state.ethers.address;
});

const store = useStore();
const router = useRouter();

// Loads logged in user's owned items.
const loadOwnedItems = async () => {
  if (isConnected.value) {
    // the following code loads all known items
    await store.dispatch(
      'mint/loadItems',
      {
        ethersStore: store.state.ethers // wallet address (specifically: walletConnect)
      },
      {
        root: true
      }
    );
    isLoadingMyItems.value = false;
  }
};

// Computed property for wallet connection.
const isConnected = computed(() => {
  return store.state.ethers.canSign;
});

watch(currentAddress, (curAdd, oldAdd) => {
  if (curAdd == null) {
    // wallet has locked or disconnected altogether
    store.state.ethers.canSign = false;
    store.dispatch('login/initialize');
  }

  // essentially waits until wallet is ready
  if (curAdd !== oldAdd) {
    ensureWalletConnected(400000)
      .then(loadAll)
      .catch(err => {
        let do_nothing = err;
      });
  }
});

// this following function should NOT be required and
// we should be able to watch over isConnected
// TODO: either get the watch to work or move this into ethers.service
function ensureWalletConnected(timeout) {
  var start = Date.now();
  return new Promise(waitForWallet);

  // waitForWallet makes the decision whether the condition is met
  // or not met or the timeout has been exceeded which means
  // this promise will be rejected
  function waitForWallet(resolve, reject) {
    // check if wallet connect has ever been enabled in the past
    store.commit('checkWalletConnectStore');
    // if we have connected previously, dispatch automagic connection
    if (store.state?.walletconnect?.connected) {
      store.dispatch('ethers/connectWCProvider');
    }
    if (store.state?.ethers?.canSign) resolve(store.state.ethers.canSign);
    else if (timeout && Date.now() - start >= timeout)
      reject(new Error('timeout'));
    else setTimeout(waitForWallet.bind(this, resolve, reject), 30);
  }
}
</script>
<style scoped lang="scss">
.myClones {
  height: 100%;

  .text {
    font-size: 24;
    font-family: PressStart, sans-serif;
    height: 100%;
    text-align: center;
    margin-top: 70px;
  }

  .none {
    padding: 60px 20px;
    padding-bottom: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    .heading {
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 40px;
      line-height: 1em;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .cloneGrid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 30px;
    padding-bottom: 100px;
    justify-content: center;
    gap: 15px;
  }
}

@media (max-width: 1300px) {
  .myClones .cloneGrid {
    // grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 970px) {
  .myClones .cloneGrid {
    // grid-template-columns: 1fr 1fr;
  }
}
</style>

'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';
import { errMsg, processError } from '../utility';

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

const big2ts = bn => bn.toNumber() * 1000;

const calcEth = (eth, wei) => {
  return ethers.utils.formatEther(ethers.utils.parseEther(eth).sub(wei));
};

// attempts to hit contract. on failure,
// flags caller.
// returns: error message (string)
export const checkValidProvider = async () => {
  try {
    // find out if network is valid (or fail)
    let p = await ethersService.getProvider();
    // check what current provider network is using
    let n = await p.getNetwork();
    if (config.forceNetwork != n.chainId) {
      // if current network is not the one user in enforcing, fail
      return errMsg('WrongNetwork');
    }
    // attempts to call expected function from known mint contract
    let networkId = ethers.utils.hexValue(n.chainId);
    let s = config.shopAddress[networkId];
    let c = new ethers.Contract(s, config.mintShopABI, p);
    let co = config.itemCollections[networkId];
    await c.displays[
      ethers.utils.solidityKeccak256(
        ['address', 'address'],
        [co, ethers.constants.AddressZero]
      )
    ];
    return null;
  } catch (error) {
    return errMsg('WrongNetwork');
  }
};

const getSoldCount = async function () {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];

  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );
  let sold = await shopContract.sold();

  return sold ? sold.toNumber() : 0;
};

// loads shop config
const loadShopConfig = async function (dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let shopAddress = config.shopAddress[networkId];
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  let collection = config.itemCollections[networkId];
  let token = config.tokenAddress[networkId];
  let hashedKeyEthSale = ethers.utils.solidityKeccak256(
    ['address', 'address'],
    [collection, ethers.constants.AddressZero]
  );
  //console.info('hashedKeyEthSale:', hashedKeyEthSale);
  let hashedKeyTokenSale = ethers.utils.solidityKeccak256(
    ['address', 'address'],
    [collection, token]
  );
  //console.info('hashedKeyTokenSale:', hashedKeyTokenSale);

  let ethData = await shopContract.displays(hashedKeyEthSale);
  let tokenData = await shopContract.displays(hashedKeyTokenSale);

  /// The total number of items sold by the shop.
  let sold = await shopContract.sold(collection);

  /// The time when the public sale begins.
  let ethStart = ethData.startTime;

  /// The time when the public sale ends.
  let ethEnd = ethData.endTime;

  /// The price at which the public sale is set.
  let ethPrice = ethData.price;

  /// The time when the token sale begins.
  let tokStart = tokenData.startTime;

  /// The time when the token sale ends.
  let tokEnd = tokenData.endTime;

  /// The price at which the token sale is set.
  let tokPrice = tokenData.price;

  // Default to ETH sale for the following data
  /// The maximum number of items from the `collection` that may be sold.
  let totalCap = ethData.totalCap;

  /// The maximum number of items that a single address may purchase.
  let callerCap = ethData.callerCap;

  /// The maximum number of items that may be purchased in a single transaction.
  let transactionCap = ethData.transactionCap;

  return {
    tokenStartTime: big2ts(tokStart),
    tokenEndTime: 1743107210000,
    tokenStartingPrice: ethers.utils.formatEther(tokPrice),
    tokenStartingPriceWei: tokPrice,
    ethStartTime: big2ts(ethStart),
    ethEndTime: 1743107210000,
    ethStartingPrice: ethers.utils.formatEther(ethPrice),
    ethStartingPriceWei: ethPrice,
    sold: sold,
    transactionCap: transactionCap,
    totalCap: totalCap,
    callerCap: callerCap
  };
};

// Get the current price of an item
const currentPrice = async function () {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];

  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  let currentPrice = await shopContract
    .price()
    .then(p => ethers.utils.formatEther(p));
  return currentPrice;
};

// Purchase an item from the shop.
const purchaseItem = async function (
  qnt,
  isToken,
  mintStore,
  ethersStore,
  dispatch
) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];
  let tokenAddress = isToken
    ? config.tokenAddress[networkId]
    : ethers.constants.AddressZero;
  let collection = config.itemCollections[networkId];

  let isValid = await ethers.utils.isAddress(shopAddress);
  if (!isValid) {
    return; // TODO: throw useful error.
  }

  // Used while checking for token spend approval.
  let signer = await provider.getSigner();

  // Purchase the item.
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    signer
  );

  /*
   * calculate price: if whitelist, take in consideration shopConfig and token/eth values.
   *                 otherwise, ping shopContract for current price
   */
  let price = isToken
    ? mintStore.shopConfig.tokenStartingPriceWei
    : mintStore.shopConfig.ethStartingPriceWei;
  if (isToken) {
    price = ethers.BigNumber.from('0');
    let erc20Contract = new ethers.Contract(
      tokenAddress,
      config.erc20ABI,
      signer
    );
    let allowance = ethers.BigNumber.from(0);
    try {
      allowance = await erc20Contract.allowance(
        ethersStore.address,
        shopAddress
      );
    } catch (error) {
      await processError(errMsg(error.message), true, dispatch);
      // give up
      return;
    }
    // TODO: make a more robust check for the exact required allowance
    if (!allowance.gt(0)) {
      let approvalTx = null;
      try {
        approvalTx = await erc20Contract.approve(
          shopAddress,
          ethers.constants.MaxUint256
        ); // TODO: prompt the user for a configurable allowance input
      } catch (error) {
        await processError(errMsg(error.message), true, dispatch);
      }

      await dispatch(
        'alert/info',
        {
          message: 'Transaction Submitted',
          metadata: {
            transaction: approvalTx.hash
          },
          duration: 300000
        },
        { root: true }
      );
      await approvalTx.wait();
    }
  }

  let totalSpend = price.mul(qnt);

  let gasPrice = 0;
  /*
  await provider.getGasPrice().then(currentGasPrice => {
    gasPrice = ethers.utils.hexlify(parseInt(currentGasPrice));
  });
  */

  /*
  console.info(
    'Minting:',
    qnt,
    ethers.utils.formatEther(totalSpend),
    ethers.utils.formatEther(gasPrice)
  );
  */

  let gasLimit = ethers.utils.hexlify('0x100000');
  /*
  let gasLimit = await provider.estimateGas(
    shopContract.mint(collection, tokenAddress, qnt, {
      value: totalSpend
    })
  ); 
  */

  let mintTx = null;
  try {
    mintTx = await shopContract.mint(collection, tokenAddress, qnt, {
      value: totalSpend
      //gasPrice: gasPrice,
      //gasLimit: gasLimit
    });
  } catch (error) {
    await processError(errMsg(error.message), true, dispatch);
  }

  if (mintTx != null) {
    await dispatch(
      'alert/info',
      {
        message: 'Transaction Submitted',
        metadata: {
          transaction: mintTx.hash
        },
        duration: 300000
      },
      { root: true }
    );

    await mintTx
      .wait()
      .then(async result => {
        //console.info('Result from mint attempt', result);
        await dispatch('alert/clear', '', { root: true });
        await dispatch(
          'alert/info',
          {
            message: 'Transaction Confirmed',
            metadata: {
              transaction: mintTx.hash
            },
            duration: 10000
          },
          { root: true }
        );
      })
      .catch(async function (error) {
        console.log(error);
        await processError(errMsg(error.message), true, dispatch);
      });
  }
};

async function safeQueryFilter(contract, event, startBlock, endBlock) {
  let start = startBlock;
  // let end = await provider.getBlockNumber()
  let end = endBlock;
  let endRange = end;

  let results = [];
  do {
    if (start >= endRange) {
      endRange = end;
    }
    let singleTransfers = [];
    try {
      singleTransfers = await contract.queryFilter(event, start, endRange);
    } catch (e) {
      let mid = Math.round((start + endRange) / 2);
      endRange = mid;
      continue;
    }
    results = results.concat(singleTransfers);
    start = endRange + 1;
  } while (endRange < end);
  return results;
}

// Parse the items owned by a given address.
const loadItems = async function (mintStore, resolveMetadata, dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider, network, networkId;
  try {
    provider = await ethersService.getProvider();
    network = await provider.getNetwork();
    networkId = ethers.utils.hexValue(network.chainId);
  } catch (error) {
    return;
  }

  let itemCollectionAddress = config.itemCollections[networkId];

  let isValid = ethers.utils.isAddress(itemCollectionAddress);
  if (!isValid) {
    return; // TODO: throw useful error.
  }

  // Check for token spend approval.
  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();

  let itemContract = new ethers.Contract(
    itemCollectionAddress,
    config.itemABI,
    provider
  );

  // Check relative item transfer events to determine this user's current
  // inventory.
  let ownershipData = {};
  // Single transfer events.
  let filterToWallet = itemContract.filters.Transfer(null, walletAddress);
  let filterFromWallet = itemContract.filters.Transfer(walletAddress, null);
  let singleTransfers = [
    ...(await safeQueryFilter(itemContract, filterToWallet)),
    ...(await safeQueryFilter(itemContract, filterFromWallet))
  ].sort((a, b) => {
    let block = a.blockNumber - b.blockNumber;
    if (block !== 0) {
      return block;
    }

    return a.transactionIndex - b.transactionIndex;
  });
  /*
  console.info(
    `Processing ${singleTransfers.length} single transfer events ...`
  );
  */
  for (let t of singleTransfers) {
    ownershipData[t.args.tokenId.toNumber()] = {
      collectionAddress: t.address,
      tokenId: t.args.tokenId.toNumber(),
      blockHash: t.blockHash,
      blockNumber: t.blockNumber,
      data: t.data,
      txHash: t.transactionHash,
      txIndex: t.transactionIndex,
      metadata: null,
      owner: t.args.to
    };
  }

  let myItems = Object.values(ownershipData).filter(
    tokenData => tokenData.owner === walletAddress
  );

  const metadataURI = await itemContract.metadataUri();
  for (let item of myItems) {
    let resp = {
      image: `https://nftstorage.link/ipfs/bafybeidgfngn7qfz7ixrtucpeivim5rrrm522jbuzgwfk7mg5gqo3xkn34/${item.tokenId}.jpg`
    };
    if (resolveMetadata) {
      resp = await axios
        .get(metadataURI + item.tokenId)
        .then(resp => {
          return resp.data;
        })
        .catch(err => {
          return { err: err.message };
        });
    }
    item.metadata = resp;
  }
  return myItems;
};

// Parse the allowance and number of token minted by a given address.
const loadMintedItems = async function (ethersStore, dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let shopAddress = config.shopAddress[networkId];
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  let collection = config.itemCollections[networkId];
  let mintedCount = await shopContract.purchaseCounts(
    collection,
    ethersStore.address
  );

  return mintedCount.toNumber();
};

// Parse the allowance and number of token owned by a given address.
const loadTokenInfo = async function (ethersStore, dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];
  let tokenAddress = config.tokenAddress[networkId];
  let signer = await provider.getSigner();

  let erc20Contract = new ethers.Contract(
    tokenAddress,
    config.erc20ABI,
    signer
  );
  let allowance = await erc20Contract.allowance(
    ethersStore.address,
    shopAddress
  );
  let balance = await erc20Contract.balanceOf(ethersStore.address);
  let ethBalance = await provider.getBalance(ethersStore.address);

  return {
    tokenBalance: balance,
    hasToken: balance.gt(0),
    tokenAllowance: allowance,
    hasAllowedTokenAccess: allowance.gt(0),
    ethBalance: ethBalance,
    hasEth: ethBalance.gt(0)
  };
};

const approveToken = async function ({ dispatch }) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];
  let tokenAddress = config.tokenAddress[networkId];
  let signer = await provider.getSigner();

  let erc20Contract = new ethers.Contract(
    tokenAddress,
    config.erc20ABI,
    signer
  );

  let approvalTx = null;
  let approvalAmount = ethers.constants.MaxUint256;
  try {
    approvalTx = await erc20Contract.approve(shopAddress, approvalAmount); // TODO: prompt the user for a configurable allowance input

    await dispatch(
      'alert/info',
      {
        message: 'Transaction Submitted',
        metadata: {
          transaction: approvalTx.hash
        },
        duration: 300000
      },
      { root: true }
    );
    let result = await approvalTx.wait();
    await dispatch('alert/clear', '', { root: true });
    await dispatch(
      'alert/info',
      {
        message: 'SUPER Approved for Mint',
        metadata: {
          transaction: approvalTx.hash
        },
        duration: 10000
      },
      { root: true }
    );
    return { tx: result, approvalAmount };
  } catch (error) {
    await processError(errMsg(error.message), true, dispatch);
  }
};

// Export the user service functions.
export const mintService = {
  loadShopConfig,
  currentPrice,
  getSoldCount,
  purchaseItem,
  loadItems,
  calcEth,
  loadTokenInfo,
  loadMintedItems,
  approveToken
};

<template>
  <Ethereum :callback="initialize" />
  <SplashArea id="splash" />
  <AboutSection id="about" />
  <FAQSection id="faqs" />
  <TeamSection id="team" />
</template>
<script setup>
import Ethereum from '/src/components/common/Ethereum.vue';
import SplashArea from './components/SplashArea.vue';
import AboutSection from './components/AboutSection.vue';
import FAQSection from './components/FAQSection.vue';
import TeamSection from './components/TeamSection.vue';

import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const isLoggedIn = computed(() => {
  return store.state.ethers.address;
});

const initialize = async () => { };

</script>
<style scoped lang="scss">
.home {
  text-align: center;
  padding: 50px;
}
</style>

